import * as THREE from "three";

// Init Scene
const scene = new THREE.Scene();

// Init Camera
// FOV = 75
// Aspect ratio = window.innerWidth / window.innerHeight
// Near plane = 0.1
// Far plane = 1000
const camera = new THREE.PerspectiveCamera(
  75,
  window.innerWidth / window.innerHeight,
  0.1,
  1000
);

// Init WebGL renderer to canvas
const renderer = new THREE.WebGLRenderer({
  canvas: document.querySelector("#galaxy-canvas"),
});

// Config renderer
renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(window.innerWidth, window.innerHeight);

// Set camera starting position
camera.position.x = 0;
camera.position.y = 20;
camera.position.z = 100;

// Render scene and camera to canvas
renderer.render(scene, camera);

window.addEventListener("resize", () => {
  const camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.render(scene, camera);
});

// Add pointlight
const pointLight = new THREE.PointLight(0xffffff);
pointLight.position.set(100, 300, 150);
scene.add(pointLight);

// scene.add(new THREE.AmbientLight(0xffffff, 0.5));

// Add lighthelper
// scene.add(new THREE.PointLightHelper(pointLight));

// Add gridhelper
// scene.add(new THREE.GridHelper(200, 50));

// Activate orbitcontrols for moving in 3d space with camera
// const controls = new OrbitControls(camera, renderer.domElement);

// Generate stars
// Amount of stars
const starsAmount = 1500;

function addStar() {
  // Generate a sphere geometry with radius = 0.25, widthSegments = 14, heightSegments = 14
  const geometry = new THREE.SphereGeometry(0.4, 14, 14);
  // White material
  const material = new THREE.MeshStandardMaterial({ color: 0xffffff });
  const star = new THREE.Mesh(geometry, material);

  let x, y, z;
  let distance;

  // Generate random coordinates for stars to spread, ensuring they are outside the 50 radius
  const maxRadius = 750;
  do {
    x = THREE.MathUtils.randFloatSpread(maxRadius);
    y = THREE.MathUtils.randFloatSpread(maxRadius);
    z = THREE.MathUtils.randFloatSpread(maxRadius);

    // Calculate the Euclidean distance from the origin
    distance = Math.sqrt(x * x + y * y + z * z);
  } while (Math.abs(distance) <= 100);

  star.position.set(x, y, z);
  scene.add(star);
}

Array(starsAmount).fill().forEach(addStar);

function moveCamera() {
  const t = document.body.getBoundingClientRect().top;
  camera.position.x = t * -0.0002;
  camera.position.y = t * -0.0002;
  camera.position.z = t * -0.05;
}

document.body.onscroll = moveCamera;

function animate() {
  requestAnimationFrame(animate);
  renderer.render(scene, camera);
}

animate();
